import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import MainTitle from '../components/uielements/MainTitle'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'
import Spacer from '../components/uielements/Spacer'
import styled from 'styled-components'
import { screenSizes } from '../lib/constants'
import Wrapper from '../components/Wrapper'
import { Helmet } from 'react-helmet'
import Seo from '../components/seo'

const FaqBlock = styled.div`
    a {
        font-weight: bold;
        margin: 5px;
        padding: 10px 5px;
        display: inline-block;
        color: ${({ theme }) => theme.blue};
        text-decoration: none;
        border-bottom: 1px dashed #ccc;

        &:hover {
            color: ${({ theme }) => theme.purple};
        }

        @media (max-width: ${screenSizes.md}px) {
            margin: 5px 0;
            padding: 10px 0;
        }
    }
`

const FaqPage = ({ data: { faq } }) => {
    const { t, i18n } = useTranslation()
    return (
        <Layout>
            <Helmet>
                <meta property="og:title" content={t('faq')} />
            </Helmet>
            <Wrapper>
                <Seo title={t('faq')} />
                <MainTitle>{t('faq')}</MainTitle>
                <Spacer top="5" md={{ top: '2' }} />
                {faq.nodes.map(
                    (faq, index) =>
                        faq.node_locale === i18n.language && (
                            <FaqBlock key={faq.id}>
                                <Link to={faq.slug} title={faq.title}>
                                    {index + 1}. {faq.title}
                                </Link>
                            </FaqBlock>
                        )
                )}
            </Wrapper>
        </Layout>
    )
}

export const query = graphql`
    query faqQuery {
        faq: allContentfulFaq {
            nodes {
                title
                excerpt {
                    raw
                }
                node_locale
                slug
                id
            }
        }
    }
`

export default FaqPage
